//import "./src/styles/global.sass";

window.onscroll = function changeNav() {
  var navBar = document.getElementById("principalPerson"),
    top = window.scrollY,
    height = 10;

  if (!navBar) {
    return;
  }

  if (top <= height) {
    navBar.classList.remove("shrink");
  } else if (top >= height) {
    navBar.classList.add("shrink");
  }
};
